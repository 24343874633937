<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-md-4">
        <ProjectsStatusesV2 />
      </div>
      <div class="col-md-4">
        <ActionItemsV4 />
      </div>
      <div class="col-md-4">
        <!-- <ActionItems /> -->
        <!-- <ActionItemsV3 /> -->

        <div class="accordion" id="accordionExampleV2">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                ActionItemsV2
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ActionItemsV2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectsStatusesV2 from '@/components/ProjectsStatusesV2';
  // import ActionItems from '@/components/ActionItems';
  import ActionItemsV2 from '@/components/ActionItemsV2';
  // import ActionItemsV3 from '@/components/ActionItemsV3';
  import ActionItemsV4 from '@/components/ActionItemsV4';

  export default {
    components: {
      ProjectsStatusesV2,
      // ActionItems,
      ActionItemsV2,
      // ActionItemsV3,
      ActionItemsV4,
    },
  };
</script>
