<template>
  <div>
    <h6>
      ActionItems V4
      <a
        href="https://console.firebase.google.com/u/0/project/fytouklaki-prod/database/fytouklaki-prod-default-rtdb/data"
        target="_blank">
        FirebaseRTDB
      </a>
    </h6>

    <!-- Filters -->
    <div class="filters mb-3">
      <!-- Project filters -->
      <button
        v-for="projectId in pendingProjectIds"
        :key="projectId"
        @click="filterByProject(projectId)"
        :class="{ 'active': selectedProjectId === projectId }"
        class="btn btn-outline-primary btn-sm me-2 mb-2">
        {{ projectId ? projectId : 'No Project' }}
      </button>

      <!-- WIP filter -->
      <button
        @click="toggleWipFilter"
        :class="{ 'active': showWipOnly }"
        class="btn btn-outline-primary btn-sm me-2 mb-2">
        WIP
      </button>

      <!-- Clear filters button -->
      <!-- <button @click="clearFilters" class="btn btn-outline-secondary btn-sm mb-2">Clear Filters</button> -->
    </div>
    <strong>
      {{ Object.entries(selected_ais_to_view).length }}
      items.
    </strong>
    <br />
    <br />
    <div v-for="(ai, key, index) in selected_ais_to_view" :key="key" :class="get_admin_status_class(ai)">
      <strong>[{{ index + 1 }} / {{ Object.entries(selected_ais_to_view).length }}]</strong>
      &nbsp;
      <!-- <pre>
        {{ key }}
        {{ JSON.stringify(ai, null, 2) }}
      </pre> -->
      <span v-html="get_tags_html(ai)"></span>
      <strong>{{ ai?.date }}</strong>
      -
      <strong>{{ ai?.time?.split('.').slice(0, 3).join(':') }}</strong>
      -
      <span v-html="get_event_html(ai)"></span>

      &nbsp;
      <button
        class="btn btn-secondary btn-sm"
        type="button"
        v-if="ai?.status == null || ai?.status == 'PENDING'"
        :disabled="update_in_progress"
        @click.prevent="update_action_item(ai?.id, 'COMPLETED')">
        <div v-if="update_in_progress && ai?.id == wip_item" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Complete-it
      </button>
      <button
        class="btn btn-secondary btn-sm"
        type="button"
        v-if="ai?.status == 'COMPLETED'"
        :disabled="update_in_progress"
        @click.prevent="update_action_item(ai?.id, 'PENDING')">
        <div v-if="update_in_progress && ai?.id == wip_item" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        WIP-it
      </button>
      <span v-html="update_status"></span>
      <span
        v-if="
          [
            'project_offer',
            'project_added',
            'project_message_sent',
            'project_files_uploaded',
            'project_payment',
            'provider_payment_pending',
          ].includes(ai?.event)
        ">
        <br />
        <strong>ProjectId:</strong>
        &nbsp;
        <a :href="`/projects/${ai?.data?.project_id}`" target="_blank">{{ ai?.data?.project_id }}</a>
        <br />
      </span>
      <span v-if="['project_offer', 'project_message_sent'].includes(ai?.event)">
        <strong>OfferId:</strong>
        &nbsp;
        <a :href="`/projects/${ai?.data?.project_id}?viewid=${ai.data?.offer_id}`" target="_blank">
          {{ ai?.data?.offer_id }}
        </a>
        <br />
      </span>
      <span v-if="['personal_payment_info_updated'].includes(ai?.event)">
        <br />
        <strong>User:</strong>
        &nbsp;
        <a :href="`/users?user=${ai?.data?.user_email}`" target="_blank">
          {{ ai?.data?.user_email }}
        </a>
        <br />
      </span>
      <br />

      <span v-html="get_event_contents(ai)"></span>
      <br />

      <!-- EVENT SPECIFIC ACTIONS -->

      <span v-if="['project_added', 'project_offer', 'provider_payment_pending'].includes(ai?.event)">
        <div v-if="ai?.event === 'project_offer'" style="margin-left: 60px; background-color: lightyellow">
          <ProjectInfoV2 :projectId="ai?.data?.project_id" />
        </div>
        <div v-else>
          <ProjectInfoV2 :projectId="ai?.data?.project_id" />
        </div>
        <br />
      </span>

      <span v-if="['project_message_sent'].includes(ai?.event)">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_for_message_sent(ai)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Transmit, Notify & Complete AI
        </button>
      </span>

      <span v-if="['project_added'].includes(ai?.event)">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_approving_project_added(ai)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Approve & Complete AI
        </button>
        &nbsp;
        <button
          class="btn btn-secondary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_archiving_project_added(ai)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Archive & Complete AI
        </button>
      </span>

      <span v-if="['project_offer'].includes(ai?.event)">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_approving_project_offer(ai, ai?.data?.amount / 100)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Approve + 0€
        </button>
        &nbsp;
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_approving_project_offer(ai, ai?.data?.amount / 100 + 5)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Approve + 5€
        </button>
        &nbsp;
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_approving_project_offer(ai, ai?.data?.amount / 100 + 10)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Approve + 10€
        </button>
        &nbsp;
        <button
          class="btn btn-primary btn-sm"
          type="button"
          :disabled="ai_in_progress[ai?.id]"
          @click.prevent="autocomplete_ai_by_approving_project_offer(ai, ai?.data?.amount / 100 + 20)">
          <div v-if="ai_in_progress[ai?.id]" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          Approve + 20€
        </button>
      </span>

      <span v-html="ai_status[ai?.id]"></span>
      <hr />
    </div>
    <!-- <a href="/action-items">View all events..</a>
    <br />
    <br /> -->
  </div>
</template>

<script>
  import ProjectInfoV2 from '@/components/ProjectInfoV2';
  import { appAlertBuilder } from '@/helpers/app.js';
  import { ref as rtdbRef, rtdb, push, set, onValue, remove } from '@/services/firebase';
  var MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;

  export default {
    components: {
      ProjectInfoV2,
    },
    data() {
      return {
        pendingAIs: {},
        completedAIs: {},
        ais_by_project: {},
        selected_ais_to_view: {},
        wip_item: null,

        ai_in_progress: {},
        ai_status: {},

        action_items_list: [],

        update_in_progress: null,
        update_status: null,

        selectedProjectId: null,
        showWipOnly: true, // WIP filter is active by default
        pendingProjectIds: [],
      };
    },

    computed: {
      // pendingProjectIds() {
      //   var self = this;
      //   // console.log('producing projectIds from selected_ais_to_view', self.selected_ais_to_view);
      //   return [...new Set(Object.values(self.selected_ais_to_view).map((item) => item.data.project_id))].sort();
      // },
    },

    methods: {
      toggleWipFilter() {
        var self = this;
        self.showWipOnly = !self.showWipOnly;
        self.selectedProjectId = null;

        self.select_ais_to_view();
      },

      filterByProject(projectId) {
        var self = this;
        console.log('to filter by project', projectId);
        self.selectedProjectId = projectId;

        self.select_ais_to_view();
      },

      // clearFilters() {
      //   this.showWipOnly = false;
      //   this.selectedProjectId = null;
      // },

      get_admin_status_class: function (ai) {
        if (ai?.status == 'COMPLETED') {
          return 'item-completed';
        } else if (ai?.status == 'PENDING') {
          return 'item-pending';
        } else {
          return '';
        }
      },

      helper_format_uploaded_files: function (ai) {
        let files = ai.data?.files;
        let output = '';
        for (let i = 0; i < files.length; i++) {
          output += `<a href="${files[i]['file_url']}" target="_blank">${files[i]['original_filename']}</a> (E: ${ai.data?.uploader_user_email})<br>`;
        }
        return output;
      },

      get_tags_html: function (ai) {
        var self = this;

        let output = '';

        if (['project_message_sent'].includes(ai?.event)) {
          let message = ai?.data?.message;
          let matches = self.findSubstrings(message, ['€', 'insta', 'ευρώ', 'ευρω']);
          if (matches.length > 0) {
            console.log(ai?.id, matches);
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
          }
          return output;
        }

        if (['project_offer'].includes(ai?.event)) {
          let description = ai?.data?.description;
          let matches = self.findSubstrings(description, ['€', 'insta', 'ευρώ', 'ευρω']);
          if (matches.length > 0) {
            console.log(ai?.id, matches);
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
            output += `<span class="badge rounded-pill bg-danger">DANGEROUS MESSAGE [${matches}]</span> - `;
          }
          return output;
        }

        return output;
      },

      findSubstrings: function (mainString, substrings) {
        return substrings.filter((substring) => substring.trim().length > 0 && mainString.includes(substring));
      },

      get_event_html: function (ai) {
        if (ai?.event == 'project_offer') {
          return '<span class="badge rounded-pill bg-warning">' + ai?.event + '</span>';
        } else if (ai?.event == 'project_added') {
          return '<span class="badge rounded-pill bg-primary">' + ai?.event + '</span>';
        } else if (ai?.event == 'project_message_sent') {
          return '<span class="badge rounded-pill bg-secondary">' + ai?.event + '</span>';
        } else if (ai?.event == 'user_deleted') {
          return '<span class="badge rounded-pill bg-dark">' + ai?.event + '</span>';
        } else if (ai?.event == 'project_files_uploaded') {
          return '<span class="badge rounded-pill bg-success">' + ai?.event + '</span>';
        } else if (ai?.event == 'personal_payment_info_updated') {
          return '<span class="badge rounded-pill bg-info">' + ai?.event + '</span>';
        } else if (ai?.event == 'project_payment') {
          return '<span class="badge rounded-pill bg-danger">' + ai?.event + '</span>';
        } else if (ai?.event == 'pwa_installation') {
          return '<span class="badge rounded-pill bg-info">' + ai?.event + '</span>';
        } else if (ai?.event == 'provider_payment_pending') {
          return '<span class="badge rounded-pill bg-danger">' + ai?.event + '</span>';
        } else if (ai?.event == 'hubspot_webhook') {
          return '<span class="badge rounded-pill bg-secondary">' + ai?.event + '</span>';
        } else {
          return 'NO_EVENT_HTML';
        }
      },

      get_event_contents: function (ai) {
        var self = this;
        if (ai?.event == 'project_offer') {
          return `${ai.data?.email} &nbsp; ${ai.data?.tel} &nbsp;<strong>Amount: ${
            ai.data?.amount / 100
          } €</strong><br>${ai.data?.description}<br>`;
        } else if (ai?.event == 'project_added') {
          return `${ai.data?.email}<br>${ai.data?.description}<br>`;
        } else if (ai?.event == 'project_message_sent') {
          let sender_type = ai.data?.room.endsWith('provider') ? 'Πάροχος/Provider' : 'Αιτών/Requester';
          return `<strong>Room:</strong> ${ai.data?.room}<br><strong>From (${sender_type}):</strong> [${ai.data?.sender_email}]<br><strong>To:</strong> [${ai.data?.target_recipient_email}]<br>${ai.data?.message}<br>`;
        } else if (ai?.event == 'user_deleted') {
          return `${ai.data?.user_id}<br>${ai.data?.user_email}<br>`;
        } else if (ai?.event == 'project_files_uploaded') {
          return `${self.helper_format_uploaded_files(ai)}<br>`;
        } else if (ai?.event == 'personal_payment_info_updated') {
          let user_payments_info = ai.data.user_payments_info;
          let selected_method = user_payments_info.method;
          let selected_info = user_payments_info[selected_method];

          const keys = Object.keys(selected_info);
          let tmp_output = '';
          keys.forEach((key) => {
            const value = selected_info[key];
            tmp_output += `${key}: ${value}<br>`;
          });

          // return `${JSON.stringify(user_payments_info)}<br>${selected_method}<br>${JSON.stringify(selected_info)}<br>`;
          return `${selected_method}<br>${tmp_output}<br>`;
        } else if (ai?.event == 'project_payment') {
          return `<a href="https://dashboard.stripe.com/payments/${ai.data?.payment_id}" target="_blank">${ai.data?.payment_id}</a><br>`;
        } else if (ai?.event == 'pwa_installation') {
          return `${ai.data?.user_agent}<br>`;
        } else if (ai?.event == 'hubspot_webhook') {
          return `<pre>${JSON.stringify(
            ai.data?.payload,
            null,
            2
          )}</pre><br><a href="https://app.hubspot.com/live-messages/${ai.data?.payload[0]?.portalId}/inbox/${
            ai.data?.payload[0]?.objectId
          }" target="_blank">https://app.hubspot.com/live-messages/${ai.data?.payload[0]?.portalId}/inbox/${
            ai.data?.payload[0]?.objectId
          }</a>`;
        } else {
          return 'NO_EVENT_CONTENTS';
        }
      },

      autocomplete_ai_for_message_sent: async function (ai) {
        var self = this;

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        self.ai_in_progress[ai?.id] = true;
        let [transmit_result, transmit_message, transmit_data] = await self.transmit_chatroom_message(ai);
        self.ai_status[ai?.id] = `<br>Transmit: ${transmit_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!transmit_result) {
          return;
        }

        await delay(100);

        self.ai_in_progress[ai?.id] = true;
        let [notify_result, notify_message, notify_data] = await self.notify_for_chatroom_message(ai);
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `Notify: ${notify_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        // if (!notify_result) {
        //   return;
        // }

        await delay(100);

        self.ai_in_progress[ai?.id] = true;
        let [update_result, update_message, update_data] = await self.update_action_item(ai?.id, 'COMPLETED');
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `Update AI: ${update_result}<br>`;
        self.ai_in_progress[ai?.id] = false;
      },

      transmit_chatroom_message: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        console.log('ai=>', ai);

        let requester_user_id = null;
        let provider_user_id = null;
        if (ai?.data?.room.endsWith('-provider')) {
          // provider to requester message
          requester_user_id = ai?.data?.target_recipient;
          provider_user_id = ai?.data?.sender;
        } else {
          // requester to provider message
          requester_user_id = ai?.data?.sender;
          provider_user_id = ai?.data?.target_recipient;
        }
        console.log('ai=>', ai, 'requester_user_id=>', requester_user_id, 'provider_user_id=>', provider_user_id);

        await self.$store.dispatch('getValidToken');
        return fetch(
          `${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/offers/${ai?.data?.offer_id}/messages/transmit`,
          {
            method: 'POST',
            headers: {
              'Authorization': self.$store.state.token,
            },
            body: JSON.stringify({
              requester_user_id: requester_user_id,
              provider_user_id: provider_user_id,
              message: ai?.data?.message,
            }),
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // self.ai_status[ai?.id] = appAlertBuilder(message, 'success');
              // console.log(data);

              return [true, 'Chatroom message transmitted.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      notify_for_chatroom_message: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        console.log('ai=>', ai);

        let notify_email = ai?.data?.target_recipient_email;
        console.log('ai=>', ai, 'notify_email=>', notify_email);

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/offers/${ai?.data?.offer_id}/messages/notify`, {
          method: 'POST',
          headers: {
            'Authorization': self.$store.state.token,
          },
          body: JSON.stringify({
            notify_email: notify_email,
          }),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // self.ai_status[ai?.id] = appAlertBuilder(message, 'success');
              // console.log(data);

              return [true, 'Chatroom message recipient notified.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      update_action_item: async function (item_id, item_new_status) {
        var self = this;

        self.update_in_progress = true;
        self.wip_item = item_id;
        console.log('wip_item is now', self.wip_item);

        if (this.showWipOnly) {
          await self.mark_item_as_completed(item_id, item_new_status);
        } else {
          await self.mark_item_as_pending(item_id, item_new_status);
        }

        self.wip_item = null;
        self.update_in_progress = false;
        return [true, 'Success', null];
      },

      mark_item_as_completed: async function (item_id, item_new_status) {
        var self = this;

        let key_to_update = null;
        for (const [key, value] of Object.entries(self.pendingAIs)) {
          console.log(`Key: ${key}, Value: ${value}`);
          if (value['id'] == item_id) {
            key_to_update = key;
            break;
          }
        }
        console.log('key_to_update', key_to_update);

        const originalItem = self.pendingAIs[key_to_update];
        console.log('originalItem', originalItem);

        // Create new object with only the fields we want
        const itemData = {
          id: originalItem.id,
          data: originalItem.data,
          status: item_new_status,
          completedAt: Date.now(),
        };
        console.log('itemData-AFTER', itemData);

        // Use set() instead of push() to keep the same key
        await set(rtdbRef(rtdb, `/completed-action-items/${key_to_update}`), itemData);

        // Remove from pending
        await remove(rtdbRef(rtdb, `/pending-action-items/${key_to_update}`));
      },

      mark_item_as_pending: async function (item_id, item_new_status) {
        var self = this;

        let key_to_update = null;
        for (const [key, value] of Object.entries(self.completedAIs)) {
          console.log(`Key: ${key}, Value: ${value}`);
          if (value['id'] == item_id) {
            key_to_update = key;
            break;
          }
        }
        console.log('key_to_update', key_to_update);

        const originalItem = self.completedAIs[key_to_update];
        console.log('originalItem', originalItem);

        // Create new object with only the fields we want
        const itemData = {
          id: originalItem.id,
          data: originalItem.data,
          status: item_new_status,
        };
        console.log('itemData-AFTER', itemData);

        // Use set() instead of push() to keep the same key
        await set(rtdbRef(rtdb, `/pending-action-items/${key_to_update}`), itemData);

        // Remove from pending
        await remove(rtdbRef(rtdb, `/completed-action-items/${key_to_update}`));
      },

      autocomplete_ai_by_approving_project_added: async function (ai) {
        var self = this;

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        self.ai_in_progress[ai?.id] = true;
        let [approve_result, approve_message, approve_data] = await self.approve_project_added(ai);
        self.ai_status[ai?.id] = `<br>Approved: ${approve_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!approve_result) {
          return;
        }

        await delay(100);

        self.ai_in_progress[ai?.id] = true;
        let [update_result, update_message, update_data] = await self.update_action_item(ai?.id, 'COMPLETED');
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `Update AI: ${update_result}<br>`;
        self.ai_in_progress[ai?.id] = false;
      },

      autocomplete_ai_by_archiving_project_added: async function (ai) {
        var self = this;

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        self.ai_in_progress[ai?.id] = true;
        let [archive_result, archive_message, archive_data] = await self.archive_project_added(ai);
        self.ai_status[ai?.id] = `<br>Archived: ${archive_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!archive_result) {
          return;
        }

        await delay(100);

        self.ai_in_progress[ai?.id] = true;
        let [update_result, update_message, update_data] = await self.update_action_item(ai?.id, 'COMPLETED');
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `Update AI: ${update_result}<br>`;
        self.ai_in_progress[ai?.id] = false;
      },

      approve_project_added: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/approve`, {
          method: 'PUT',
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then((response) => response.json())
          .then(function (response) {
            console.log(response);
            var success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // console.log(data);

              return [true, 'Project approved.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },
      archive_project_added: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/archive`, {
          method: 'PUT',
          headers: {
            'Authorization': self.$store.state.token,
          },
          body: JSON.stringify({
            status: 'CANCELLED', // to cancel the project added and archive it
          }),
        })
          .then((response) => response.json())
          .then(function (response) {
            console.log(response);
            var success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;
            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // console.log(data);

              return [true, 'Project archived.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      autocomplete_ai_by_approving_project_offer: async function (ai, new_euro_amount) {
        var self = this;

        if (!confirm(`Are you sure you want to approve this project offer with a new amount of ${new_euro_amount}€?`)) {
          return;
        }

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        self.ai_in_progress[ai?.id] = true;
        let [update_result, update_message, update_data] = await self.update_project_offer_amount(ai, new_euro_amount);
        self.ai_status[ai?.id] = `<br>Offer Updated: ${update_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!update_result) {
          return;
        }

        await delay(100);

        // to remove later
        if (!confirm(`Are you sure you want to approve this project offer?`)) {
          return;
        }

        self.ai_in_progress[ai?.id] = true;
        let [approve_result, approve_message, approve_data] = await self.approve_project_offer(ai);
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `<br>Offer Approved: ${approve_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!approve_result) {
          return;
        }

        await delay(100);

        // to remove later
        if (!confirm(`Are you sure you want to notify requester for this project offer?`)) {
          return;
        }

        self.ai_in_progress[ai?.id] = true;
        let [notify_result, notify_message, notify_data] = await self.notify_project_offer(ai);
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `<br>Requester Notified: ${notify_result}<br>`;
        self.ai_in_progress[ai?.id] = false;

        if (!notify_result) {
          return;
        }

        await delay(100);

        self.ai_in_progress[ai?.id] = true;
        let [complete_result, complete_message, complete_data] = await self.update_action_item(ai?.id, 'COMPLETED');
        self.ai_status[ai?.id] = self.ai_status[ai?.id] + `Completed AI: ${complete_result}<br>`;
        self.ai_in_progress[ai?.id] = false;
      },

      update_project_offer_amount: async function (ai, new_euro_amount) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/offers/${ai?.data?.offer_id}`, {
          method: 'PUT',
          headers: {
            'Authorization': self.$store.state.token,
          },
          body: JSON.stringify({
            data: {
              amount: new_euro_amount * 100,
            },
          }),
        })
          .then((response) => response.json())
          .then(function (response) {
            console.log(response);
            var success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // console.log(data);

              return [true, 'ProjectOffer amount updated.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      approve_project_offer: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/offers/${ai?.data?.offer_id}/approve`, {
          method: 'POST',
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then((response) => response.json())
          .then(function (response) {
            console.log(response);
            var success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // console.log(data);

              return [true, 'ProjectOffer approved.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      notify_project_offer: async function (ai) {
        var self = this;
        self.ai_in_progress[ai?.id] = true;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${ai?.data?.project_id}/offers/${ai?.data?.offer_id}/notify`, {
          method: 'POST',
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then((response) => response.json())
          .then(function (response) {
            console.log(response);
            var success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.ai_in_progress[ai?.id] = false;
              // console.log(data);

              return [true, 'ProjectOffer notification completed.', data];
            }
          })
          .catch(function (error) {
            console.log(error);
            self.ai_in_progress[ai?.id] = false;
            // self.ai_status[ai?.id] = appAlertBuilder(error, 'danger');

            return [false, String(error), null];
          });
      },

      select_ais_to_view: function () {
        var self = this;
        console.log('selecting ais to view..', self.showWipOnly);

        if (self.selectedProjectId != null) {
          console.log('Filtering only for project: ', self.selectedProjectId);

          if (self.showWipOnly) {
            self.selected_ais_to_view = Object.fromEntries(
              Object.entries(self.pendingAIs).filter(([key, value]) => value.data.project_id == self.selectedProjectId)
            );
          } else {
            self.selected_ais_to_view = Object.fromEntries(
              Object.entries(self.completedAIs).filter(
                ([key, value]) => value.data.project_id == self.selectedProjectId
              )
            );
          }
        } else {
          if (self.showWipOnly) {
            self.selected_ais_to_view = Object.fromEntries(Object.entries(self.pendingAIs).slice(0, 1000));
          } else {
            self.selected_ais_to_view = Object.fromEntries(Object.entries(self.completedAIs).slice(0, 30));
          }
        }

        console.log('selected_ais_to_view', Object.entries(self.selected_ais_to_view).length);
      },
    },
    created() {
      var self = this;
      // self.fetch_action_items('PENDING');
      self.pendingAIsRef = rtdbRef(rtdb, '/pending-action-items');
      self.completedAIsRef = rtdbRef(rtdb, '/completed-action-items');
    },
    mounted() {
      onValue(
        this.pendingAIsRef,
        (snapshot) => {
          this.pendingAIs = snapshot.val() || {};
          console.log('pendingAIs', Object.entries(this.pendingAIs).length);

          this.pendingProjectIds = [
            ...new Set(Object.values(this.pendingAIs).map((item) => item.data.project_id)),
          ].sort();
          console.log('pendingProjectIds', this.pendingProjectIds);

          this.select_ais_to_view();
        },
        (error) => {
          console.log('FirebaseError:', error);
          if (error.code === 'PERMISSION_DENIED') {
            console.error('Permission denied for pending action items:', error.message);
            alert('You do not have permission to access pending action items. Admin access required.');
          }
        }
      );
      onValue(
        this.completedAIsRef,
        (snapshot) => {
          this.completedAIs = snapshot.val() || {};
          console.log('completedAIs', Object.entries(this.completedAIs).length);
          this.select_ais_to_view();
        },
        (error) => {
          console.log('FirebaseError:', error);
          if (error.code === 'PERMISSION_DENIED') {
            console.error('Permission denied for completed action items:', error.message);
            alert('You do not have permission to access completed action items. Admin access required.');
          }
        }
      );
    },
  };
</script>

<style scoped>
  .item-completed {
    background-color: #94c296;
  }
  /* .item-pending {
  background-color: #ff9248;
} */
</style>
